import { useSettingsStore } from '@/store'

export default {
  mounted: function (el, binding) {
    const { value, arg = 'click' } = binding || {};
    const settingsStore = useSettingsStore()
    
    el.clickSelf = () => {
      if (settingsStore.isSound) {
        document.getElementById(`${arg}Sound`).play(); // sound
      }
    }

    el.addEventListener('click', el.clickSelf)
  },
  // unmounted: function (el) {
  //   setTimeout(() => {
  //     el.removeEventListener('click', el.clickSelf)
  //   }, 500)
  // }
}
