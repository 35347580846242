import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import { useRoute, useRouter } from 'vue-router'


export default {
  __name: 'NavBar',
  props: ['title', 'hideBack', 'size'],
  setup(__props) {


// const emit = defineEmits(['onClickRight'])

const route = useRoute()
const router = useRouter()

const goBack = () => {
  if(route.name === 'login' || route.name === 'register') {
    router.replace({ name: 'game' })
  // } else if (route.name === 'forgetPassword') {
  } else {
    router.back()
  } 
}


return (_ctx, _cache) => {
  const _component_van_icon = _resolveComponent("van-icon")
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")
  const _directive_audio = _resolveDirective("audio")

  return (_openBlock(), _createBlock(_component_van_nav_bar, {
    title: __props.title,
    border: false,
    class: _normalizeClass(__props.size)
  }, {
    left: _withCtx(() => [
      (!(__props.hideBack === true || __props.hideBack === ''))
        ? _withDirectives((_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "back-btn flex justify-center",
            onClick: goBack
          }, [
            _createVNode(_component_van_icon, {
              name: "arrow-left",
              class: "back-icon"
            })
          ])), [
            [_directive_audio, void 0, "close"]
          ])
        : _createCommentVNode("", true)
    ]),
    right: _withCtx(() => [
      _renderSlot(_ctx.$slots, "right")
    ]),
    _: 3
  }, 8, ["title", "class"]))
}
}

}