import { allowMultipleToast } from 'vant';
import HeaderBar from '@/components/HeaderBar.vue';
import NavBar from '@/components/NavBar.vue';
import MyToast from './MyToast';
import MyDialog from './MyDialog';

export { setupDirective } from '@/directive'

export function setComponents(app) {
  app.component('HeaderBar', HeaderBar);
  app.component('NavBar', NavBar);
  
  allowMultipleToast();
  
  app.config.globalProperties.$toast = MyToast;
  app.config.globalProperties.$myDialog = MyDialog;
}
