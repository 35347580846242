import { useSettingsStore } from '@/store'
export const useAudio = () => {
  const playAudio = (name) => {
    if (name === 'bgm' && useSettingsStore().isMusic) {
      let bgmEl = document.getElementById('bgm')
      if(bgmEl.paused) {
        bgmEl?.play(); // music
      } 
    } else if (name != 'bgm' && useSettingsStore().isSound) {
      document.getElementById(`${name}Sound`)?.play(); // sound
    }  
  }

  const pauseAudio = (name) => {
    let audioId = name
    if (name != 'bgm') {
      audioId = `${name}Sound`
    }
    document.getElementById(audioId)?.pause();
  }

  return { 
    playAudio, pauseAudio,
  };
} 
  