import { useUserStore } from '@/store'
import WsSocket from '@/plugins/ws-socket'

const urlCallback = () => {
  const uid = useUserStore().uid;
  if (!uid) {
    window.location.reload()
  }
  let currentUrl = window.location
  let wsUrl = ''
  if (currentUrl.protocol === 'https:') {
    wsUrl = 'wss:'
  } else {
    wsUrl = 'ws:'
  }
  wsUrl += '//' + currentUrl.host
  wsUrl += `/webSocket/${uid}`
  return wsUrl;
}

class Connect {
  conn

  constructor() {
    this.conn = new WsSocket(urlCallback, {
      onError: (evt) => {
        console.log('Websocket Connection failure callback method', evt)
      },
      // Websocket 连接成功回调方法
      onOpen: () => {
        // 更新 WebSocket 连接状态
        // useUserStore().updateSocketStatus(true)
      },
      // Websocket 断开连接回调方法
      onClose: () => {
        // 更新 WebSocket 连接状态
        // useUserStore().updateSocketStatus(false)
      }
    })

    this.bindEvents()
  }

  /**
   * 连接
   */
  connect() {
    this.conn.connection()
  }

  /**
   * 断开连接
   */
  disconnect() {
    this.conn.close()
  }

  /**
   * 连接状态
   * @returns WebSocket 连接状态
   */
  isConnect() {
    if (!this.conn.connect) return false

    return this.conn.connect.readyState === 1
  }

  /**
   * 推送事件消息
   * @param event 事件名
   * @param data  数据
   */
  emit(event, data) {
    this.conn.emit(event, data)
  }


  // 回调函数的注册
  registerCallBack (eventStr, callBack) {
    this.conn.on(eventStr, callBack);
  }

  // 取消某一个回调函数
  unRegisterCallBack (eventStr) {
    this.conn.clear(eventStr);
  }

  /**
   * 绑定监听消息事件
   */
  bindEvents() {
    this.onPing()
    this.onPong()
  }

  onPing() {
    this.conn.on('ping', () => this.emit('pong', ''))
  }

  onPong() {
    this.conn.on('pong', () => {})
  }

  // onEventError() {
  //   this.conn.on('event_error', (data) => {
  //     window['$message'] && window['$message'].error(JSON.stringify(data))
  //   })
  // }
}

// 导出单例
export default new Connect()
