import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "license-footer" }
const _hoisted_2 = { class: "license-title" }
const _hoisted_3 = { class: "flex justify-center" }
const _hoisted_4 = { class: "app-license" }

export function render(_ctx, _cache) {
  const _component_van_image = _resolveComponent("van-image")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('license.title')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_van_image, {
        class: "logo-pagcor",
        src: require('@/assets/img/common/logo-pagcor.png')
      }, null, 8, ["src"]),
      _createVNode(_component_van_image, {
        class: "logo-gamezone",
        src: require('@/assets/img/common/logo-gamezone.png')
      }, null, 8, ["src"])
    ]),
    _createElementVNode("div", _hoisted_4, "@" + _toDisplayString(_ctx.$t('license.content')), 1)
  ]))
}