import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "age-verifiction-popup" }
const _hoisted_2 = { class: "popup-main" }
const _hoisted_3 = { class: "popup-header" }
const _hoisted_4 = { class: "popup-title" }
const _hoisted_5 = { class: "popup-content" }
const _hoisted_6 = { class: "margin-bottom font-large" }
const _hoisted_7 = { class: "highlight" }
const _hoisted_8 = { class: "margin-bottom" }
const _hoisted_9 = { class: "margin-bottom font-large" }
const _hoisted_10 = { class: "flex justify-center margin-bottom" }
const _hoisted_11 = { class: "action-btn-warp flex justify-between" }

import { storeToRefs } from 'pinia';
import { usePopupStore } from '@/store'


export default {
  __name: 'AgeVerifiction',
  emits: ['onClose'],
  setup(__props, { emit: __emit }) {

const emit = __emit

const popupStore = usePopupStore();

const doExit = () => {
  if (window.$bridge) {
    window.$bridge.callHandler('exit');
  } else {
    window.location.href = 'https://www.google.com';
  }
}

return (_ctx, _cache) => {
  const _component_van_image = _resolveComponent("van-image")
  const _component_van_button = _resolveComponent("van-button")
  const _component_van_popup = _resolveComponent("van-popup")
  const _directive_audio = _resolveDirective("audio")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_popup, {
      show: true,
      position: "bottom",
      "close-on-click-overlay": false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_van_image, {
              class: "logo-pagcor",
              src: require('@/assets/img/common/logo-pagcor.png')
            }, null, 8, ["src"]),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('ageVerification.title').toUpperCase()), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('ageVerification.content1.0')), 1),
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('ageVerification.content1.1')), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('ageVerification.content1.2')), 1)
            ]),
            _createElementVNode("ul", _hoisted_8, [
              _createElementVNode("li", null, _toDisplayString(_ctx.$t('ageVerification.content2.0')), 1),
              _createElementVNode("li", null, _toDisplayString(_ctx.$t('ageVerification.content2.1')), 1),
              _createElementVNode("li", null, _toDisplayString(_ctx.$t('ageVerification.content2.2')), 1),
              _createElementVNode("li", null, _toDisplayString(_ctx.$t('ageVerification.content2.3')), 1),
              _createElementVNode("li", null, _toDisplayString(_ctx.$t('ageVerification.content2.4')), 1)
            ]),
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('ageVerification.content3')), 1),
            _createElementVNode("div", _hoisted_10, [
              _cache[3] || (_cache[3] = _createElementVNode("div", null, "SLOTMAX.COM", -1)),
              _createElementVNode("div", {
                class: "highlight underline",
                onClick: _cache[0] || (_cache[0] = $event => (_unref(popupStore).openDocument('termsAndCondition')))
              }, _toDisplayString(_ctx.$t('ageVerification.terms')), 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.$t('common.and')), 1),
              _createElementVNode("div", {
                class: "highlight underline",
                onClick: _cache[1] || (_cache[1] = $event => (_unref(popupStore).openDocument('privacyPolicy')))
              }, _toDisplayString(_ctx.$t('ageVerification.privacyPolicy')), 1)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _withDirectives((_openBlock(), _createBlock(_component_van_button, {
                class: "action-btn exit",
                onClick: doExit
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.exit')), 1)
                ]),
                _: 1
              })), [
                [_directive_audio, void 0, "close"]
              ]),
              _withDirectives((_openBlock(), _createBlock(_component_van_button, {
                class: "action-btn",
                onClick: _cache[2] || (_cache[2] = $event => (emit('onClose')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.accept')), 1)
                ]),
                _: 1
              })), [
                [_directive_audio]
              ])
            ])
          ])
        ])
      ]),
      _: 1
    })
  ]))
}
}

}