import { defineStore } from 'pinia'
// import { useFBQ } from '@/hooks'
import { getBalance, queryFbEvent } from '@/api';

export const useAccountStore = defineStore('account', {
  // persist: true,
  state: () => {
    return {
      allAmount: 0, // 总金额
      balance: 0, // 可用余额
      withdrawalAmount: 0, // 可提现金额
      deposit: 0, // 保险箱中的金额
      point: 0,  // 积分
      surplus: 0, // 剩余流水
    }
  },
  getters: {},
  actions: {
    fetchBalance() {
      getBalance().then((res) => {
        const { userAccountVO, userPointVO, userRiskVO, fbEvent } = res.data || {};
        this.allAmount = userAccountVO.allAmount || 0;
        this.balance = userAccountVO.balance || 0;
        this.withdrawalAmount = userAccountVO.withdrawalAmount || 0;
        this.deposit = userAccountVO.deposit || 0;
        this.point = userPointVO.value || 0;
        this.surplus = userRiskVO.surplus || 0;
        // if (fbEvent) {
        //   this.doFbEvent('Purchase');
        // }
      })
    },
    doFbEvent(eventName) {
      queryFbEvent().then((res) => {
        const { amount, currency } = res.data || {};
        useFBQ().registerEvent(eventName, { value: amount, currency });
      })
    }
  }
})
