// vant 国际化
import { Locale } from 'vant';
import enUS from 'vant/lib/locale/lang/en-US';
import viVN from 'vant/lib/locale/lang/vi-VN';
 
function changeLang(newlang) {
  switch (newlang) {
    case 'vi-VN':
      Locale.use(newlang, viVN);
      localStorage.setItem('i18n', newlang)
      break;
    default:
      Locale.use('en-US', enUS);
      localStorage.setItem('i18n', 'en-US')
      break;
  }
}
 
export function Local(mylang) {
  changeLang(mylang)
}