import dropsize from './dropsize'
import focus from './focus'
import audio from './audio'

const directives = {
  dropsize,
  focus,
  audio
}

export function setupDirective(app) {
  for (const key in directives) {
    app.directive(key, directives[key])
  }
}
