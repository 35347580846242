import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "header-bar flex justify-between" }
const _hoisted_2 = {
  key: 0,
  class: "center-wrap flex justify-end"
}
const _hoisted_3 = {
  key: 1,
  class: "center-wrap flex justify-end"
}
const _hoisted_4 = { class: "balance-wrap" }
const _hoisted_5 = { class: "flex justify-center flex-1" }
const _hoisted_6 = { class: "balance-value" }
const _hoisted_7 = {
  key: 0,
  class: "badge-wrap dot"
}

import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore, useAccountStore, useReddotStore } from "@/store";
import { useHelpTool } from '@/hooks';
import MenuPanel from '@/components/MenuPanel.vue';


export default {
  __name: 'HeaderBar',
  setup(__props) {

const userStore = useUserStore();
const { uid, avatar, vip, invitationCode } = storeToRefs(userStore);
const accountStore = useAccountStore();
const { balance, point } = storeToRefs(accountStore);
const redDotStore = useReddotStore();
const { loginBonusRewardNum, inboxUnread, taskRewardNum } = storeToRefs(redDotStore);

const showMenuPanel = ref(false);


return (_ctx, _cache) => {
  const _component_van_image = _resolveComponent("van-image")
  const _component_van_button = _resolveComponent("van-button")
  const _directive_audio = _resolveDirective("audio")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createVNode(_component_van_image, {
        class: "header-logo",
        src: require('@/assets/img/common/logo-title.png'),
        onClick: _cache[0] || (_cache[0] = $event => (_ctx.$router.push({ name: 'game' })))
      }, null, 8, ["src"]), [
        [_directive_audio]
      ]),
      (_ctx.$router.currentRoute.value.name === 'login' || _ctx.$router.currentRoute.value.name === 'register' || _ctx.$router.currentRoute.value.name === 'forgetPassword')
        ? _withDirectives((_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "service-btn flex justify-center",
            onClick: _cache[1] || (_cache[1] = $event => (_ctx.$router.push({ name: 'customerService' })))
          }, [
            _createVNode(_component_van_image, {
              class: "service-icon",
              src: require('@/assets/img/common/service.png')
            }, null, 8, ["src"])
          ])), [
            [_directive_audio]
          ])
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (!_unref(uid))
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _withDirectives((_openBlock(), _createBlock(_component_van_button, {
                    class: "header-btn ghost",
                    onClick: _cache[2] || (_cache[2] = $event => (_ctx.$router.push({ name: 'login' })))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.login').toUpperCase()), 1)
                    ]),
                    _: 1
                  })), [
                    [_directive_audio]
                  ]),
                  _withDirectives((_openBlock(), _createBlock(_component_van_button, {
                    class: "header-btn",
                    onClick: _cache[3] || (_cache[3] = $event => (_ctx.$router.push({ name: 'register' })))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.signup').toUpperCase()), 1)
                    ]),
                    _: 1
                  })), [
                    [_directive_audio]
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_van_image, {
                        class: "coin-icon",
                        src: require('@/assets/img/common/coin.png')
                      }, null, 8, ["src"]),
                      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$filter.outputAmount(_unref(balance))), 1)
                    ]),
                    _withDirectives(_createVNode(_component_van_image, {
                      class: "action-icon",
                      src: require('@/assets/img/common/refresh.png'),
                      onClick: _cache[4] || (_cache[4] = $event => (_unref(accountStore).fetchBalance()))
                    }, null, 8, ["src"]), [
                      [_directive_audio]
                    ])
                  ]),
                  _withDirectives((_openBlock(), _createElementBlock("div", {
                    class: "menu-icon",
                    onClick: _cache[5] || (_cache[5] = $event => (showMenuPanel.value = true))
                  }, [
                    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "menu-line" }, null, -1)),
                    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "menu-line" }, null, -1)),
                    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "menu-line short" }, null, -1)),
                    (_unref(inboxUnread))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7))
                      : _createCommentVNode("", true)
                  ])), [
                    [_directive_audio]
                  ])
                ]))
          ], 64))
    ]),
    _createVNode(MenuPanel, {
      show: showMenuPanel.value,
      onOnClose: _cache[6] || (_cache[6] = $event => (showMenuPanel.value = false))
    }, null, 8, ["show"])
  ], 64))
}
}

}