import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "popup-main" }
const _hoisted_2 = { class: "popup-header" }
const _hoisted_3 = { class: "title-wrap flex justify-center" }
const _hoisted_4 = {
  key: 1,
  class: "popup-title"
}
const _hoisted_5 = { class: "popup-content" }


export default {
  __name: 'PopupForm',
  props: ['title', 'titleIcon', 'style1', 'style2', 'style3'],
  emits: ['onClose'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit

return (_ctx, _cache) => {
  const _component_van_image = _resolveComponent("van-image")
  const _component_van_icon = _resolveComponent("van-icon")
  const _component_van_popup = _resolveComponent("van-popup")
  const _directive_audio = _resolveDirective("audio")

  return (_openBlock(), _createElementBlock("div", {
    ref: "selfRef",
    class: _normalizeClass(["form-popup", {'style1': __props.style1 != undefined, 'style2': __props.style2 != undefined, 'style3': __props.style3 != undefined }])
  }, [
    _createVNode(_component_van_popup, {
      show: true,
      "close-on-click-overlay": false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (__props.titleIcon)
                ? (_openBlock(), _createBlock(_component_van_image, {
                    key: 0,
                    class: "popup-title-icon",
                    fit: "contain",
                    src: __props.titleIcon
                  }, null, 8, ["src"]))
                : _createCommentVNode("", true),
              (__props.title)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(__props.title), 1))
                : _createCommentVNode("", true)
            ]),
            _withDirectives(_createVNode(_component_van_icon, {
              class: "close-icon",
              name: "close",
              onClick: _cache[0] || (_cache[0] = $event => (emit('onClose')))
            }, null, 512), [
              [_directive_audio, void 0, "close"]
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ])
      ]),
      _: 3
    })
  ], 2))
}
}

}