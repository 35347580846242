//判断机型
function setupWKWebViewJavascriptBridge(callback) {
  if (window.WKWebViewJavascriptBridge) { return callback(WKWebViewJavascriptBridge); }
  if (window.WKWVJBCallbacks) { return window.WKWVJBCallbacks.push(callback); }
  window.WKWVJBCallbacks = [callback];
  window.webkit?.messageHandlers?.iOS_Native_InjectJavascript?.postMessage(null)
}

export function setBridge(app) {
  const u = navigator.userAgent;
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  
  if (isAndroid) {
    if(window.android) {
      window.android.callHandler = function (name, data, callback) {
        if (!this[name]) {
          console.error('Bridge function [' + name + '] is not exist');
          return false;
        }
        if (data) {
          let result =  this[name](data);
          callback && callback(result);
        } else {
          let result =  this[name]();
          callback && callback(result);
        }
      }
      window.android.registerHandler = function (name, func) {
        window[name] = func;
      }
    }
    // 判断有android 并且android下有openBrowser 才确定有bridge (部分安卓浏览器下有自带android对象)
    if (window.android && window.android.openBrowser) {
      app.config.globalProperties.$bridge = window.android;
      window.$bridge = window.android;
    }
  } else if (isiOS) {
    setupWKWebViewJavascriptBridge(function (bridge) {
      app.config.globalProperties.$bridge = bridge;
      window.$bridge = bridge;
    })
  } else {
    app.config.globalProperties.$bridge = null;
  }
}