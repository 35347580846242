import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "document-popup" }
const _hoisted_2 = { class: "popup-main" }
const _hoisted_3 = { class: "popup-content" }
const _hoisted_4 = { class: "flex justify-between" }

import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { usePopupStore } from '@/store'
import PrivacyPolicy from '@/doc/PrivacyPolicy';
import TermsAndCondition from '@/doc/TermsAndCondition';


export default {
  __name: 'Document',
  props: ['type'],
  emits: ['onClose'],
  setup(__props, { emit: __emit }) {


const emit = __emit

const APP_DOCUMENT = {
  privacyPolicy: PrivacyPolicy,
  termsAndCondition: TermsAndCondition
}
const popupStore = usePopupStore();
const { documentType } = storeToRefs(popupStore);

return (_ctx, _cache) => {
  const _component_van_button = _resolveComponent("van-button")
  const _component_van_popup = _resolveComponent("van-popup")
  const _directive_audio = _resolveDirective("audio")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_popup, {
      show: true,
      "close-on-click-overlay": false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(APP_DOCUMENT[_unref(documentType)]))),
            _createElementVNode("div", _hoisted_4, [
              _withDirectives((_openBlock(), _createBlock(_component_van_button, {
                class: "action-btn ghost",
                onClick: _cache[0] || (_cache[0] = $event => (emit('onClose')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.close').toUpperCase()), 1)
                ]),
                _: 1
              })), [
                [_directive_audio, void 0, "close"]
              ]),
              _withDirectives((_openBlock(), _createBlock(_component_van_button, {
                class: "action-btn",
                onClick: _cache[1] || (_cache[1] = $event => (emit('onClose')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.ok').toUpperCase()), 1)
                ]),
                _: 1
              })), [
                [_directive_audio]
              ])
            ])
          ])
        ])
      ]),
      _: 1
    })
  ]))
}
}

}