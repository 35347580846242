import { defineStore } from 'pinia'

export const usePopupStore = defineStore('popup', {
  state: () => {
    return {
      rewards: [],
      rewardInfo: '',
      documentType: '',
      frameUrl: '',
      frameTitle: '',
      gameFrameUrl: '',
      gameFrameCloseFn: undefined,
      walletAccount: null,
      walletCloseFn: undefined,
      showReward: false,
      showFollow: false,
      showAgeVerifiction: false,
      showDocument: false,
      showInAppFrame: false,
      showGameFrame: false,
      showBindWallet: false,
    }
  },
  actions: {
    set(key, value = true) {
      this[key] = value;
    },
    showRewards(rewards, rewardInfo) {
      if(Array.isArray(rewards)) {
        this.rewards = rewards;
      } else {
        this.rewards = [{ type: "cash", amount: rewards }];
      }
      this.rewardInfo = rewardInfo;
      this.showReward = true;
    },
    openUrlInFrame(url, title = '') {
      this.frameUrl = url;
      this.frameTitle = title;
      this.showInAppFrame = true;
    },
    closeUrlInFrame() {
      this.frameUrl = '';
      this.frameTitle = '';
      this.showInAppFrame = false;
    },
    openGameFrame(url, closeCallback) {
      this.gameFrameUrl = url;
      this.gameFrameCloseFn = closeCallback || undefined;
      this.showGameFrame = true;
    },
    closeGameFrame() {
      this.gameFrameUrl = '';
      if (this.gameFrameCloseFn) {
        this.gameFrameCloseFn()
      }
      this.gameFrameCloseFn = undefined;
      this.showGameFrame = false;
    },
    openBindWallet(account, closeCallback) {
      this.walletAccount = account || null;
      this.walletCloseFn = closeCallback || undefined;
      this.showBindWallet = true;
    },
    closeBindWallet() {
      this.walletAccount = null;
      if (this.walletCloseFn) {
        this.walletCloseFn()
      }
      this.walletCloseFn = undefined;
      this.showBindWallet = false;
    },
    openDocument(type) {
      this.showDocument = true;
      this.documentType = type;
    }
  }
})
