// vant 国际化
import moment from 'moment';

function changeLang(newlang) {
  switch (newlang) {
    case 'hi-IN':
      moment.locale('hi');
      break;
    case 'bn-IN':
      moment.locale('bn');
      break;
    case 'ml-IN':
      moment.locale('ml');
      break;
    case 'ta-IN':
      moment.locale('ta');
      break;
    case 'te-IN':
      moment.locale('te');
      break;
    case 'vi-VN':
      moment.locale('vi');
      break;
    default:
      moment.locale('en');
      break;
  }
}
 
export function Local(mylang) {
  changeLang(mylang)
}