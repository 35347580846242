import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, unref as _unref } from "vue"

const _hoisted_1 = { class: "bind-wallet-title" }
const _hoisted_2 = { class: "bind-wallet-info" }
const _hoisted_3 = { class: "input-form" }
const _hoisted_4 = { class: "type-radio-wrap" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "select-icon-wrap"
}
const _hoisted_7 = { class: "input-row" }
const _hoisted_8 = { class: "input-box" }
const _hoisted_9 = { class: "input-row" }
const _hoisted_10 = { class: "input-box" }
const _hoisted_11 = { class: "tip-wrap flex align-start" }
const _hoisted_12 = { class: "tip-text" }

import { ref, reactive, computed, watch, inject, onMounted } from 'vue';
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia';
import { usePopupStore } from "@/store";
import LicenseFooter from '@/components/LicenseFooter';
import { queryWithdrawBank, saveWithdrawAccount } from '@/api';


export default {
  __name: 'BindWallet',
  emits: ['onClose'],
  setup(__props, { emit: __emit }) {

const emit = __emit

const MyToast = inject('myToast')

const { t } = useI18n()

const popupStore = usePopupStore();
const { walletAccount } = storeToRefs(popupStore);

const selfRef = ref(null);
const bankList = ref([
  { code: 'gcash', name: 'GCASH'},
  { code: 'maya', name: 'MAYA'}
]);
const activeBank = ref(null);
const form = reactive({
  account: '',
  name: '',
});

const isGcashOrMaya = computed(() => {
  return activeBank.value?.code === 'gcash' || activeBank.value?.code === 'maya';
})

const saveDisabled = computed(() => {
  if(!activeBank.value) {
    return true;
  } else if(isGcashOrMaya.value) {
    return !form.account.match(/^9[0-9]{9}$/) || form.name.length < 2;
  } else {
    return form.account.length < 0 || form.name.length < 2;
  }
})

watch(activeBank, (newValue, oldValue) => {
  if(oldValue && newValue) {
    form.account = '';
  }
})

onMounted(() => {
  // fetchWithdrawBank();
  if (walletAccount.value) {
    form.name = walletAccount.value.name;
    form.account = walletAccount.value.account;
    activeBank.value = bankList.value.find(item => item.code === walletAccount.value.bank)
  } else {
    activeBank.value = bankList.value[0]
  }
})

// const fetchWithdrawBank = () => {
//   queryWithdrawBank().then(res => {
//     bankList.value = res.data || [];
//     if (walletAccount.value) {
//       form.name = walletAccount.value.name;
//       form.account = walletAccount.value.account;
//       activeBank.value = bankList.value.find(item => item.code === walletAccount.value.bank)
//     }
//   })
// }

const doSave = () => {
  saveWithdrawAccount({
    bank: activeBank.value.code,
    name: form.name,
    account: form.account,
  }).then(res => {
    MyToast({ type: 'success', message: t('notify.operateSuccess') });
    popupStore.closeBindWallet();
  })
}

return (_ctx, _cache) => {
  const _component_van_image = _resolveComponent("van-image")
  const _component_van_field = _resolveComponent("van-field")
  const _component_van_button = _resolveComponent("van-button")
  const _component_PopupForm = _resolveComponent("PopupForm")
  const _directive_audio = _resolveDirective("audio")

  return (_openBlock(), _createElementBlock("div", {
    ref_key: "selfRef",
    ref: selfRef,
    class: "bind-wallet-popup"
  }, [
    _createVNode(_component_PopupForm, {
      title: _ctx.$t('finance.bindWallet.title').toUpperCase(),
      onOnClose: _cache[4] || (_cache[4] = $event => (emit('onClose')))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('finance.bindWallet.subTitle')), 1),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('finance.bindWallet.info')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(bankList.value, (item) => {
              return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["type-radio-item", { active: activeBank.value === item }]),
                key: item.code,
                onClick: $event => (activeBank.value = item)
              }, [
                _createElementVNode("div", null, _toDisplayString(item.name), 1),
                (activeBank.value === item)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_van_image, {
                        class: "select-icon",
                        src: require('@/assets/img/finance/select.png')
                      }, null, 8, ["src"])
                    ]))
                  : _createCommentVNode("", true)
              ], 10, _hoisted_5)), [
                [_directive_audio]
              ])
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_van_field, {
                modelValue: form.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((form.name) = $event)),
                modelModifiers: { trim: true },
                class: "input-field",
                maxlength: "32",
                placeholder: _ctx.$t('placeholder.walletName'),
                onInput: _cache[1] || (_cache[1] = $event => (form.name = _ctx.$filter.letterSpaceOnly($event.target.value))),
                onBlur: _cache[2] || (_cache[2] = $event => (form.name = _ctx.$filter.letterSpaceOnly($event.target.value) ))
              }, null, 8, ["modelValue", "placeholder"])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_van_field, {
                "model-value": "+63",
                class: "input-prefix",
                readonly: ""
              }),
              _createVNode(_component_van_field, {
                modelValue: form.account,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((form.account) = $event)),
                modelModifiers: { trim: true },
                class: "input-field",
                type: "digit",
                maxlength: isGcashOrMaya.value ? 10 : 32,
                placeholder: isGcashOrMaya.value ? _ctx.$t('placeholder.walletNumber') : ''
              }, null, 8, ["modelValue", "maxlength", "placeholder"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('finance.bindWallet.tip')), 1)
        ]),
        _withDirectives((_openBlock(), _createBlock(_component_van_button, {
          class: "submit-btn",
          onClick: doSave,
          disabled: saveDisabled.value
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.confirm').toUpperCase()), 1)
          ]),
          _: 1
        }, 8, ["disabled"])), [
          [_directive_audio]
        ]),
        _createVNode(_unref(LicenseFooter))
      ]),
      _: 1
    }, 8, ["title"])
  ], 512))
}
}

}