import { defineStore } from 'pinia'
import { queryGameType } from '@/api';

const MENU_SETTING = {
  default: { showProvider: true, showType: 0, background: { r: 250, g: 255, b: 0 } },
  hot: { showProvider: true, showType: 0, background: { r: 250, g: 255, b: 0 } },
  slot: { showProvider: true, showType: 0, background: { r: 0, g: 148, b: 255 } },
  live: { showProvider: false, showType: 1, background: { r: 250, g: 0, b: 255 } },
  sports: { showProvider: false, showType: 2, background: { r: 0, g: 240, b: 255 } },
  fish: { showProvider: true, showType: 0, background: { r: 0, g: 255, b: 56 } },
  poker: { showProvider: true, showType: 0, background: { r: 250, g: 0, b: 0 } },
  arcade: { showProvider: true, showType: 0, background: { r: 255, g: 255, b: 255 } },
};

export const useGameStore = defineStore('game', {
  state: () => {
    return {
      gameMenu: [], //游戏类型列表
      activeGameMenu: null, //当前选中的游戏类型
    }
  },
  getters: {},
  actions: {
    fetchMenu() {
      queryGameType({
        hasLogin: false
      }).then(res => {
        let _menuList = res.data || [];
        _menuList = _menuList.map(menu => {
          let setting = MENU_SETTING[menu.code] || MENU_SETTING.default
          return {
            ...menu,
            ...setting,
          }
        });
        this.gameMenu = _menuList;
      });
    },
    changeMenu(item) {
      this.activeGameMenu = item;
    },
    clearActiveMenu() {
      this.activeGameMenu = null;
    },
    set(key, value = 0) {
      this[key] = value;
    },
    getMenuSetting() {
      return MENU_SETTING
    }
  }
})
